<template>
  <v-container fluid>
    <v-card >
      <v-card-title>
        <h1>Preferences and Defaults</h1>
        <v-spacer></v-spacer>
      </v-card-title>

      <v-card-text>
        <v-tabs background-color="grey lighten-4" light color="#AF4CAB" v-model="tab">
          <v-tab>Basic</v-tab>
          <v-tab>Billing Address</v-tab>
          <v-tab>Tax Information</v-tab>
          <v-tab>Payment Settings</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
              
            <v-card flat>
              <v-card-text>
                  <BasicPrefs/>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <BillingAddress/>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text><TaxPrefs/></v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text><PaymentTerms/></v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import BillingAddress from "@/components/user/BillingAddress";
import BasicPrefs from "@/components/user/BasicPrefs";
import TaxPrefs from "@/components/user/TaxPrefs";
import PaymentTerms from "@/components/user/PaymentTerms";


export default {
  data() {
    return {
      tab: null
    };
  },
  mounted() {
      this.$store.dispatch('loadPreferences');
  },
  components: {
    BillingAddress, TaxPrefs, BasicPrefs, PaymentTerms
  },
  computed: {
      prefs() {
          return this.$store.getters.preferences;
      }
  }
};
</script>