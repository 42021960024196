<template>
  <v-card>
    <v-card-title>The address that will appear on your invoices</v-card-title>
    <v-card-text>
      <form id="form">
            <v-text-field
              v-model="streetAddress"
              label="Street Address"
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="city"
              label="City"
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            ></v-text-field>

            <v-text-field
              v-model="province"
              label="Province"
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            ></v-text-field>

            <v-text-field
              v-model="country"
              label="Country"
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            ></v-text-field>

            <v-text-field
              v-model="postalCode"
              label="Postal Code"
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            ></v-text-field>

      </form>
    </v-card-text>
    <v-card-actions>
      <v-btn :disabled="$v.$anyError || !$v.$anyDirty" class="mr-4" @click="submit">Update</v-btn>
      <!-- <v-btn @click="$emit('closeEditor')">Discard Changes</v-btn> -->
    </v-card-actions>
  </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import { maxLength, minLength } from "vuelidate/lib/validators";

export default {
  name: "EditUserAddress",
  props: { user: Object },
  mixins: [validationMixin],

  validations: {
    name: {
      maxLength: maxLength(100),
      minLength: minLength(3)
    }
  },
  data() {
    return {
      streetAddress: "",
      city: "",
      province: "",
      country: "",
      postalCode: ""
    };
  },
  mounted() {
    this.streetAddress = this.prefs.streetAddress || "";
    this.city = this.prefs.city || "";
    this.province = this.prefs.province || "";
    this.country = this.prefs.country || "";
    this.postalCode = this.prefs.postalCode || "";
  },
  computed: {
    prefs() {
      return this.$store.getters.preferences;
    }
    // nameErrors() {
    //   const errors = [];
    //   if (!this.$v.name.$dirty) return errors;
    //   !this.$v.name.maxLength &&
    //     errors.push("Name must be at most 100 characters long");
    //   !this.$v.name.required && errors.push("Name is required.");
    //   return errors;
    // },
    // emailErrors() {
    //   const errors = [];
    //   if (!this.$v.email.$dirty) return errors;
    //   !this.$v.email.email && errors.push("Must be valid e-mail");
    //   return errors;
    // }
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        let payload = {
          streetAddress: this.streetAddress,
          city: this.city,
          province: this.province,
          country: this.country,
          postalCode: this.postalCode
        };
        this.$store.dispatch("updatePreferences", payload);
      } else {
        this.$store.dispatch("setError", {
          data: { message: "Fix the highlighted fields and try again" }
        });
      }
    }
  }
};
</script>

<style scoped>
#form {
    max-width: 600px;
}

#extra-info {
  border: 1px solid gray;
  border-radius: 10px;
  padding: 20px;
}
</style>